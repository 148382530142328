import React from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { SvgIcon, SxProps, Theme } from '@mui/material';

export type LogoSizeProp = FontAwesomeIconProps['size'];
type LogoProps = {
  size?: LogoSizeProp;
  color?: string;
  sx?: SxProps<Theme>;
};

const styles = {
  '2xs': {
    width: '0.5em',
    height: 'auto',
  },

  xs: {
    width: '0.75em',
    height: 'auto',
  },

  sm: {
    width: '1em',
    height: 'auto',
  },

  lg: {
    width: '33px',
    height: 'auto',
  },

  xl: {
    width: '76px',
    height: 'auto',
  },

  '2xl': {
    width: '135px',
    height: 'auto',
  },

  '1x': {
    width: '250px',
    height: 'auto',
  },
};
type StyleKey = keyof typeof styles;

export const Logo = React.forwardRef<HTMLDivElement, LogoProps>(
  (
    {
      color = 'var(--mui-sunrise-light-primary-main)',
      size = 'sm',
      sx = {},
    }: LogoProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _,
  ): JSX.Element => (
    <SvgIcon
      aria-label="Summer health logo"
      sx={{
        ...styles[size as StyleKey],
        fill: color,
        ...sx,
      }}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255 235" role="img">
        {/* eslint-disable-next-line @stylistic/max-len */}
        <path d="M161.8,168.08l66.61-66.61-19.8-19.8-66.61,66.61V54.08h-28v94.2l-56.95-56.95-9.66-9.66-19.8,19.8,66.61,66.61H0v28H88c9.98,0,19.43-2.28,27.84-6.36,4.34-2.09,8.4-4.66,12.12-7.64,0,0,0-.01,.02-.02l-.15-.15c.06,.05,.11,.1,.17,.14,.06-.04,.11-.09,.17-.14l-.15,.15s.01,.02,.02,.02c3.72,2.98,7.78,5.55,12.12,7.64,8.41,4.08,17.86,6.36,27.84,6.36h88v-28h-94.2Z" />
      </svg>
    </SvgIcon>
  ),
);

export default Logo;
